import "./App.css";

function App() {
  return (
    <>
      <section>
        <div>
          <div class="title">
            <div>
              <p class="p1 jersey-15-regular">KODAWARIS</p>
              <p class="p2 jersey-15-regular">ver 0.0.1</p>
            </div>
          </div>
        </div>
        <div class="arrows">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </section>
      <section>aoeu</section>
    </>
  );
}

export default App;
